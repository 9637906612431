import React from 'react';
import "./Footer.css";
import { PiFacebookLogoThin, PiInstagramLogoThin, PiTwitterLogoThin } from "react-icons/pi";


const Footer = () => {
    return (
        <section className='f-wrapper'>
            <div className="flexStart paddings innerWidth f-container">
                <div className='f-content'>
                    <div className='f-content__link'>
                        <span className='subHeadingText'>Shop</span>
                        <a href='#'>Shipping</a>
                        <a href='#'>Return & Exhanges</a>
                        <a href='#'>All FAQs</a>
                        <a href='#'>Contact Us</a>
                    </div>
                    <div className='f-content__link'>
                        <span className='subHeadingText'>Services</span>
                        <a href='#'>Stores</a>
                        <a href='#'>Application Form</a>
                    </div>
                </div>
                <div className='f-content'>
                    <div className='flexColStart f-content__link'>
                        <span className='subHeadingText'>Blog</span>
                        <a href='#'>Guide A to Z</a>
                        <a href='#'>Measuring</a>
                        <a href='#'>Top mdels</a>
                    </div>
                    <div className='flexColStart f-content__link'>
                        <span className='subHeadingText'>About us</span>
                        <a href='#'>Sustainability</a>
                        <a href='#'>Mission</a>
                        <a href='#'>Materials</a>
                        <a href='#'>Our Advantages</a>
                    </div>
                </div>
                <div className='f-content'>
                    <div className='flexColStart f-search'>
                        <span className='subHeadingText'>Join us by Subscription</span>
                        <span className='secondaryText'>Be the first one to know the latest information</span>
                        <div className='flexStart search-box'>
                            <input type='text' placeholder='Your Email' />
                            <button className="button">Subscribe</button>
                        </div>
                        <div className="flexStart hero-icons">
                            <span><PiFacebookLogoThin size={35} /></span>
                            <span><PiInstagramLogoThin size={35} /></span>
                            <span><PiTwitterLogoThin size={35} /></span>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Footer