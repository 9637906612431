import React from 'react'
import "./About.css";
import { GiBigDiamondRing } from "react-icons/gi";
import { IoDiamondOutline } from "react-icons/io5";
import { GiEarrings } from "react-icons/gi";
import { PiWatchThin } from "react-icons/pi";
import { PiArrowRightThin } from "react-icons/pi";

const About = () => {
    return (
        <section className="a-wrapper">
            <div className="paddings innerWidth a-container">
                <div className="flexColStart a-head ">
                    <span className="primaryText heading">About our advantages</span>
                </div>
                <div className="flexStart a-cards">
                    <div className='flexColStart left-cards'>
                        <div className="a-card">
                            <IoDiamondOutline size={45} color='#949391' />
                            <div className='card-des'>
                                <p className='primaryText heading'>Diamonds</p>
                                <p className='description'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                                <a className='orangeText read-more' href='/'>Read more <span><PiArrowRightThin size={20} color={"#DF761D"} /></span></a>
                            </div>
                        </div>
                        <div className="a-card">
                            <PiWatchThin size={45} color='#949391' />
                            <div className='card-des'>
                                <p className='primaryText heading'>Hand Watches</p>
                                <p className='description'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                                <a className='orangeText read-more'>Read more <span><PiArrowRightThin size={20} color={"#DF761D"} /></span></a>
                            </div>
                        </div>
                    </div>
                    <div className='flexColStart right-cards'>
                        <div className="a-card">
                            <GiEarrings size={45} color='#949391' />
                            <div className='card-des'>
                                <p className='primaryText heading'>Unpaired earings</p>
                                <p className='description'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                                <a className='orangeText read-more'>Read more <span><PiArrowRightThin size={20} color={"#DF761D"} /></span></a>
                            </div>
                        </div>
                        <div className="a-card">
                            <GiBigDiamondRing size={45} color='#949391' />
                            <div className='card-des'>
                                <p className='primaryText heading'>Wedding rings</p>
                                <p className='description'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                                <a className='orangeText read-more'>Read more <span><PiArrowRightThin size={20} color={"#DF761D"} /></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About