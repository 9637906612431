import React from 'react';
import "./Blog.css";
import "swiper/css";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import data from "../../utils/slider.json";
import { sliderSettings } from '../../utils/common';
import ImageContainer from "../Common/ImageContainer/ImageContainer";
import { PiArrowRightThin } from "react-icons/pi";

const Blog = () => {
    return (
        <section className="b-wrapper">
            <div className="paddings innerWidth b-container">
                <div className="flexColStart b-head">
                    <span className="primaryText heading">Blog</span>
                </div>
                <Swiper {...sliderSettings}>
                    {data.map((card, i) => (
                        <SwiperSlide key={i}>
                            <div className="flexColStart r-card">
                                <ImageContainer src={card.image} type={"blog-image"} alt="blog-image" />
                                <span className="card-name">{card.blogHeading}</span>
                                <span className="secondaryText card-desc">
                                    {card.desc}
                                </span>
                                <a className='orangeText read-more'>Read more <span><PiArrowRightThin size={20} color={"#DF761D"} /></span></a>
                            </div>
                        </SwiperSlide>
                    ))
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default Blog