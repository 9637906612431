import React from 'react';
import Hero from "./../Hero/Hero";
import Categories from "./../Categories/Categories";
import About from "./../About/About";
import Blog from '../Blog/Blog';
import Sale from '../Sales/Sale';
import Footer from '../Footer/Footer';

const Home = () => {
    return (
        <div>
            <Hero />
            <Categories />
            <About />
            <Blog />
            <Sale />
            <Footer/>
        </div>
    )
}

export default Home