import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import Products from "./components/Products/Products";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Products />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
