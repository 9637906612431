import React from 'react';
import "./Categories.css";
import "swiper/css";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import data from "../../utils/slider.json";
import { sliderSettings } from '../../utils/common';
import { PiArrowLeftThin } from "react-icons/pi";
import ImageContainer from '../Common/ImageContainer/ImageContainer';
const Categories = () => {
    return (
        <section className="r-wrapper">
            <div className="paddings innerWidth r-container">
                <div className="flexColStart r-head">
                    <span className="primaryText heading">Shop by Categories</span>
                </div>
                <Swiper {...sliderSettings}>
                    <SliderButtons></SliderButtons>
                    {data.map((card, i) => (
                        <SwiperSlide key={i}>
                            <div className="flexColStart r-card">
                                <ImageContainer src={card.image} type={"category-image"} alt="category-image"/>
                                <span className="card-name">{card.name}</span>
                                <span className="secondaryText r-price">
                                    <span>Price from </span>
                                    <span style={{ color: "orange" }}>$</span>
                                    <span>{card.price}</span>
                                </span>
                                <button className="button">View Colletion</button>
                            </div>
                        </SwiperSlide>
                    ))
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default Categories;

const SliderButtons = () => {
    const swiper = useSwiper();
    return (
        <div className="flexCenter r-buttons">
            <button onClick={() => swiper.slidePrev()}>&lt;</button>
            <button onClick={() => swiper.slideNext()}>&gt;</button>
        </div>
    )
}