import React from 'react'
import ImageContainer from '../Common/ImageContainer/ImageContainer'
import "./Sale.css";

const Sale = () => {
    return (
        <section className='s-wrapper'>
            <div className='innerWidth flexColStart s-container'>
                <div className='paddings flexStart s-left'>
                    <div className='flexColStart s-content'>
                        <span className='orangeText'>Sale up to 70%</span>
                        <span>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</span>
                        <button className="button">Shop Now</button>
                    </div>
                    <div className='s-image'>
                        <ImageContainer src="https://images.pexels.com/photos/1721937/pexels-photo-1721937.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" type={"sales-section"} alt="left-image" />
                    </div>
                </div>
                <div className='paddings flexEnd s-right'>
                    <div className='s-image'>
                        <ImageContainer src="https://images.pexels.com/photos/3881079/pexels-photo-3881079.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" type={"sales-section"} alt="left-image" />
                    </div>
                    <div className='flexColStart s-content'>
                        <span className='orangeText'>Subscribe to our newsletter</span>
                        <span>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</span>
                        <button className="button">Shop Now</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Sale