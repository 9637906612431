import React from 'react';
import "./Header.css";

const Header = () => {
    return (
        <section className="h-wrapper">
            <div className="flexCenter paddings innerWidth h-container">
                <div className="left-header">
                    <p className="logo">perle</p>
                    <div className="left-menu">
                        <a href='/shop'>Shop</a>
                        <a href='/'>About</a>
                        <a href='/'>Blog</a>
                    </div>
                </div>
                <div className="right-menu flexEnd">
                    <a href='/'>Search</a>
                    <a href='/'>Wishlist(0)</a>
                    <a href='/'>Cart(0)</a>
                </div>
            </div>
        </section>
    )
}

export default Header