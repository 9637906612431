import React from 'react';
import "./Hero.css";
import { PiFacebookLogoThin, PiInstagramLogoThin, PiTwitterLogoThin } from "react-icons/pi";

const Hero = () => {
    return (
        <section className="hero-wrapper">
            <div className="flexCenter paddings innerWidth hero-container">
                <div className="flexColStart hero-left">
                    <div className="hero-title">
                        <h1>Distinctive jewelry</h1>
                    </div>
                    <div className="flexColStart hero-des">
                        <span className="secondaryText">Emphaszing your originality is our primary task.</span>
                        <span className="secondaryText">Create unforgettable images.</span>
                    </div>
                    <div className="hero-button">
                        <div>
                            <button className="button">View Colletion</button>
                        </div>
                        <div className="flexStart hero-icons">
                            <span><PiFacebookLogoThin size={35} /></span>
                            <span><PiInstagramLogoThin size={35} /></span>
                            <span><PiTwitterLogoThin size={35} /></span>
                        </div>
                    </div>
                </div>
                <div className="hero-right">
                    <img src="./logo192.webp" alt="image" />
                </div>
            </div>
        </section>
    )
}

export default Hero